import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import userImageUrl from "../../../../assets/images/user.png";
import botImageUrl from "../../../../assets/images/mg-face-crop.jpeg";
import key_takeaways_url from "../../../../assets/audios/key_takeaways.mp3";
import piece_of_advice_url from "../../../../assets/audios/piece_of_advice.mp3";
import stakeholder_url from "../../../../assets/audios/stakeholder.mp3";
import Parser from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { IconButton, Snackbar, Dialog, DialogContent, DialogTitle, Button, DialogActions } from "@mui/material";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import {
    ThumbDownAltOutlined,
    ThumbUpAltOutlined,
    VolumeUpOutlined,
    VolumeOffOutlined,
} from "@mui/icons-material";
import { AudioVisualizer } from "react-audio-visualize";
import ReplyIcon from '@mui/icons-material/Reply';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { ShareTextOnTwitter, ShareIcons } from "./socialShare";
import { EmailIcon, FacebookIcon, LinkedinIcon, WhatsappIcon } from "react-share";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Typewriter from "typewriter-effect";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../globals/redux/store/store.index";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import ReactMarkdown from "react-markdown";
import toWav from "audiobuffer-to-wav";
import axios from "axios";
import * as ChatActions from "../../chat.actions";
import CustomWaveForm from "../CustomWaveForm";
import { ChatService } from "../../../../services/chatService";
declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext;
    }
}
interface Props {
    messageId: string;
    text: string;
    sender: string;
    audioUrlKey: string;
    displayAudioControl: boolean;
    onFeedbackButtonClicked: (
        messageId: string,
        action: "TU" | "TD",
        feedbackText: string | null,
        checkboxResponses: string[],
    ) => void;
    messageFeedbackApiStatus: "inprogress" | "complete" | "failed" | null;
    onThumbsDownButtonClicked: (messageId: string) => void;
    feedbackAction?: "TU" | "TD" | null;
    onTextContainerResized: () => void;
    forHistory?: string;
}
const AudioUrls = {
    "key_takeaways_url": key_takeaways_url,
    "piece_of_advice_url": piece_of_advice_url,
    "stakeholder_url": stakeholder_url,
}

const AudioMessage: React.FC<Props> = ({
    messageId,
    text,
    sender,
    audioUrlKey = null,
    displayAudioControl = false,
    onFeedbackButtonClicked,
    messageFeedbackApiStatus,
    onThumbsDownButtonClicked,
    feedbackAction = null,
    onTextContainerResized,
    forHistory,
}) => {
    let className = "sent message";
    let imageUrl = userImageUrl;
    const eventSourceRef = useRef(null);
    const audioElementRef = useRef(null);
    const fullAudioRef = useRef(new Uint8Array());
    const fullAudioRefTest = useRef(new Uint8Array());
    const chunkQueueRef = useRef([]); // Queue to store incoming audio chunks

    const isPlayingRef = useRef(false);
    const isStreamingRef = useRef(true); // Ref to track if streaming is still ongoing
    const visualizerRef = useRef<HTMLCanvasElement>(null);
    const [blob, setBlob] = useState<Blob>();
    const [apicalled, setApicalled] = useState(false);
    const [BotAnswered, setBotAnswered] = useState(false);
    const audioContextRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(true); // State to track playback status
    const [isMuted, setIsMuted] = useState(false);
    const [playingAudio, setPlayingAudio] = useState(false);
    const textRef = useRef<HTMLDivElement>();
    let apiCalled = false;
    const dispatch = useDispatch();
    const chatState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.chatV2Reducer,
    );
    if (sender === "assistant") {
        className = "received message";
        imageUrl = botImageUrl;
    }

    const setMessageTypeAudio = (
    ) => {
        if (!apiCalled && !audioUrlKey ) {
            apiCalled = true
            ChatService.setMessageType(
                messageId,
            );
        }
    };

    useEffect(() => {
        if (
            messageId &&
            chatState.audioMessageId &&
            chatState.lastReceivedMessage &&
            chatState.lastReceivedMessage?.message_id == messageId
        ) {
            const message_id = chatState.audioMessageId;
            console.log('going for source')

            audio_script()



            dispatch(ChatActions.setAudioMessageId(null));
            // handleButtonClick(message_id);
        }
        // eslint-disable-next-line
    }, [messageId]);

    const [audioUrl, setAudioUrl] = useState('');

    let total_duration = 2
    let lastTime = 0
    function calculateAudioDuration(audioChunks) {
        const totalSize = audioChunks.reduce((acc, chunk) => acc + chunk.length, 0);
        const bitrate = 128000; // Replace with actual bitrate value
        if ((totalSize / (bitrate / 8)) > total_duration) {
            total_duration = totalSize / (bitrate / 8); // Duration in seconds
        }
        console.log('total duration after new chunk: ', total_duration)
    }

    function audio_script() {
        const audioElement = document.getElementById(`audioElement_${messageId}`) as HTMLAudioElement;
        // setPlayingAudio(true);
        let allAudioChunks = []
        function handlePlayButtonClick() {
            checkAudioEnded();
            audioElement.play();
        }
        var isAudioEnded = false;
        function checkAudioEnded() {
            let timeoutId;
            console.log('current played Time : total time', audioElement.currentTime, ' : ', total_duration)
            if (audioElement.currentTime >= total_duration || lastTime == audioElement.currentTime) {
                isAudioEnded = true;
                audioElement.currentTime = 0;
                audioElement.pause();
                audioElement.addEventListener('play', handlePlayButtonClick);
                clearTimeout(timeoutId);
            } else {
                lastTime = audioElement.currentTime
                timeoutId = setTimeout(checkAudioEnded, 2000);
            }
        }
        const audioSource = document.getElementById(`audioSource_${messageId}`) as HTMLMediaElement;
        const mediaSource = new MediaSource();
        audioSource.src = URL.createObjectURL(mediaSource);
        if (!audioUrlKey) {
            mediaSource.addEventListener('sourceopen', function (event) {
                var sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                var eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}audioevents/` + chatState.audioMessageId);
                eventSource.onopen = function (event) {
                    console.log('EventSource connection opened.');
                };
                eventSource.onmessage = function (event) {
                    var eventData = JSON.parse(event.data);
                    console.log('onmessage', eventData);
                    if (eventData.sequence === 'START') {
                        // setPlayingAudio(true);
                        if (chatState.isMicClicked && audioElement.paused) {
                            audioElement.play();
                        }
                    }
                    if (eventData.sequence === 'streaming') {
                        if (chatState.isMicClicked && audioElement.paused) {
                            audioElement.play();
                        }
                        appendAudioData(eventData.text);
                    } else if (eventData.sequence === 'FINAL_AUDIO_END') {
                        console.log('Received final audio end message. Closing EventSource connection.');
                        eventSource.close();
                        dispatch(
                            ChatActions.isMicClicked(0),
                        );
                        checkAudioEnded();
                    }
                };
                eventSource.onerror = function (error) {
                    console.error('EventSource failed:', error);
                    eventSource.close();
                };
                eventSource['onclose'] = function (event) {
                    if (event.wasClean) {
                        console.log('EventSource connection closed cleanly, code:', event.code, 'reason:', event.reason);
                    } else {
                        console.error('EventSource connection abruptly closed.');
                    }
                };

                var appendQueue = [];
                var isAppending = false;

                function processAppendQueue() {
                    if (appendQueue.length > 0 && !isAppending) {
                        isAppending = true;
                        var data = appendQueue.shift();
                        sourceBuffer.appendBuffer(data);
                    }
                }

                function appendToQueue(audioArray) {
                    appendQueue.push(audioArray);
                    processAppendQueue();
                }

                function appendAudioData(base64String) {
                    var audioBinary = window.atob(base64String);
                    var audioArray = new Uint8Array(audioBinary.length);
                    for (var i = 0; i < audioBinary.length; i++) {
                        audioArray[i] = audioBinary.charCodeAt(i);
                    }

                    try {
                        appendToQueue(audioArray);
                        allAudioChunks.push(audioArray);
                        calculateAudioDuration(allAudioChunks)
                    } catch (err) {
                        console.error('Error appending audio data:', err);
                    }
                }

                sourceBuffer.addEventListener('updateend', function () {
                    isAppending = false;
                    processAppendQueue();
                });
            });
        } else {
            audioSource.src = AudioUrls[audioUrlKey];
        }

        audioElement.onerror = function (error) {
            console.error('Error playing audio:', error);
        };
    }

    useEffect(() => {
        let resizeObserver = new ResizeObserver(() => {
            onTextContainerResized();
        });
        resizeObserver.observe(textRef.current);
        // eslint-disable-next-line
    }, []);

    const [isHoveredTU, setIsHoveredTU] = useState(false);
    const [isHoveredTD, setIsHoveredTD] = useState(false);
    const [isHoveredCopy, setIsHoveredCopy] = useState(false);
    const [isHoveredShare, setIsHoveredShare] = useState(false);
    const [feedbackSelected, setFeedbackSelected] = useState(null);

    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    const copyToClipboard = () => {
        copy(text);
        setIsSnackbarOpen(true);
    };

    const findTargetText = (messageId) => {
        let previousMessage = { message_id: 1, message: "test" };

        const messageIndex = chatState.messages.findIndex(msg => msg.message_id === messageId);

        if (messageIndex !== -1) {
            if (messageIndex > 0) {
                previousMessage = {
                    message_id: chatState.messages[messageIndex - 1].message_id,
                    message: chatState.messages[messageIndex - 1].message
                };
            }
        }

        return previousMessage;
    };

    const textToShare = text;
    const combinedText = `${findTargetText(messageId).message}\n\n${textToShare}`;
    const twitterText = `Check out QnA with Marshall's AI Avatar. Great insights there. Give it a try at`;
    const QnAText = `Question: ${findTargetText(messageId).message}\n\nAnswer: ${textToShare}`;

    const [redirectMessage, setRedirectMessage] = useState("")
    const [isOpen, setIsOpen] = useState(false);

    const openDialog = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const handleEmailClick = () => {
        const subject = encodeURIComponent("Check out my QnA with Marshall's AI Avatar");
        const body = encodeURIComponent(`Below is the conversation I had with Dr. Marshall. I found it quite insightful. Check it out. You too may want to give it a try!\n\n\ Question: ${findTargetText(messageId).message}\n\n Answer: ${textToShare.substring(0, 1150)}\n\n Give it a try at ${"https://marshallgoldsmith.ai/"}`);

        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.open(mailtoLink, '_blank');
    };

    const handleWhatsAppClick = () => {
        const body = encodeURIComponent(`Check out my QnA with Marshall's AI Avatar\n\nGreat insights!\n\nQuestion: ${findTargetText(messageId).message}\n\nAnswer: ${textToShare}\n\nGive it a try at ${"https://marshallgoldsmith.ai/"}`);

        const Link = ` https://wa.me/?text=${body}`;
        window.open(Link, '_blank');
    };

    const [snackbarOpenShare, setSnackbarOpenShare] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(5);

    useEffect(() => {
        let countdown;
        if (snackbarOpenShare) {
            countdown = setInterval(() => {
                setSecondsLeft((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else {
            setSecondsLeft(5);
        }

        return () => clearInterval(countdown);
    }, [snackbarOpenShare]);

    const handleLinkedInClick = () => {
        copy(QnAText);
        setSnackbarOpenShare(true);
        setRedirectMessage("LinkedIn");
        setTimeout(() => {
            setSnackbarOpenShare(false);
            const ShareLink = "https://www.linkedin.com/sharing/share-offsite/?url=https://marshallgoldsmith.ai";
            window.open(ShareLink, '__blank');
        }, 5000);
    };

    const handleFacebookClick = () => {
        copy(QnAText);
        setSnackbarOpenShare(true);
        setRedirectMessage("Meta");
        setTimeout(() => {
            setSnackbarOpenShare(false);
            const FbShareLink = "https://www.facebook.com/sharer/sharer.php?u=https://marshallgoldsmith.ai";
            window.open(FbShareLink, '__blank');
        }, 5000);
    };

    const handleShareSnackbarClose = () => {
        setSnackbarOpenShare(false);
    };

    const typingSpeed = 24; // Adjust typing speed as needed
    const [displayText, setDisplayText] = useState('');
    const currentIndexRef = useRef(0);
    let timeoutId;

    useEffect(() => {
        const typeText = () => {
            if (currentIndexRef.current < text.length) {
                const newText = text.slice(0, currentIndexRef.current + 1);
                setDisplayText(newText);
                currentIndexRef.current++;
                timeoutId = setTimeout(typeText, typingSpeed);
            }
        };

        typeText();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [text, typingSpeed]);

    useEffect(() => {
        let typingStopped = false;

        const checkTypingStopped = () => {
            if (!typingStopped && displayText === text) {
                typingStopped = true;
                console.log("Typing stopped");
                dispatch(ChatActions.setStreamStarted(0));
            }
        };

        checkTypingStopped();

        return () => {
            checkTypingStopped();
        };
    }, [displayText, text]);
    const audioRef = useRef(null);

    return (
        <div className={className}>
            <div className={sender === "user" ? "top-user" : "top"}>
                {sender === "user" && (
                    <>
                        <div className="user-text" ref={textRef}>
                            {Parser(sanitizeHtml(text))}
                        </div>
                        <div className="image">
                            <img
                                src={imageUrl}
                                alt="ub"
                                className="image-user"
                            />
                        </div>
                    </>
                )}
                {sender === "assistant" && (
                    <>
                        <div className="image">
                            <img src={imageUrl} alt="ub" className="image" />
                        </div>
                        <div className="answer-block">
                            <div className="text-t" ref={textRef}>
                                {sender === "assistant" ? (
                                    <ReactMarkdown>
                                        {chatState.lastReceivedMessage?.message_id == messageId && chatState.setStreamStarted == 1
                                            ? displayText
                                            : text}
                                    </ReactMarkdown>
                                ) : (
                                    Parser(sanitizeHtml(text))
                                )}
                            </div>
                            <div
                                className={
                                    sender === "assistant"
                                        ? "actions actions-panel"
                                        : "hidden actions actions-panel"
                                }
                            >
                                {sender === "assistant" && (
                                    <>
                                        <div>
                                            <>
                                                {/* <audio ref={audioRef} controls>
                                                <source src={key_takeaways_url} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                            <hr /> */}
                                                <audio className={`audio-player `} onPlay={setMessageTypeAudio} id={`audioElement_${messageId}`} controls>
                                                    <source id={`audioSource_${messageId}`} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                                {/* {BotAnswered && (
                                                <IconButton
                                                    style={{ bottom: "7px", position: "relative", right: "30px" }}
                                                    className={`button  ${apicalled ? 'hidden-audio' : ''}`}
                                                    title="Speaker"
                                                    onClick={independantElevenLabsAudioAPICall}
                                                >
                                                    <VolumeUp />
                                                </IconButton>
                                            )} */}
                                            </>

                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <IconButton
                                                className="button"
                                                title="Good Answer"
                                                disabled={
                                                    messageFeedbackApiStatus ===
                                                    "inprogress"
                                                }
                                                onClick={() => {
                                                    onFeedbackButtonClicked(
                                                        messageId,
                                                        "TU",
                                                        null,
                                                        [],
                                                    );
                                                    setFeedbackSelected("TU");
                                                }}
                                                onMouseEnter={() =>
                                                    setIsHoveredTU(true)
                                                }
                                                onMouseLeave={() =>
                                                    setIsHoveredTU(false)
                                                }
                                                style={{
                                                    color:
                                                        feedbackSelected ===
                                                            "TU"
                                                            ? "#00490A"
                                                            : isHoveredTU
                                                                ? "#696969"
                                                                : "silver",
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ThumbUpAltOutlined />
                                            </IconButton>
                                            <IconButton
                                                className="button"
                                                title="Incorrect Answer"
                                                disabled={
                                                    messageFeedbackApiStatus ===
                                                    "inprogress"
                                                }
                                                onClick={() => {
                                                    onThumbsDownButtonClicked(
                                                        messageId,
                                                    );
                                                    setFeedbackSelected("TD");
                                                }}
                                                onMouseEnter={() =>
                                                    setIsHoveredTD(true)
                                                }
                                                onMouseLeave={() =>
                                                    setIsHoveredTD(false)
                                                }
                                                style={{
                                                    color:
                                                        feedbackSelected ===
                                                            "TD"
                                                            ? "#c80815"
                                                            : isHoveredTD
                                                                ? "#696969"
                                                                : "silver",
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ThumbDownAltOutlined />
                                            </IconButton>
                                            <IconButton
                                                className="button"
                                                title="Copy Message"
                                                onClick={copyToClipboard}
                                                onMouseEnter={() =>
                                                    setIsHoveredCopy(true)
                                                }
                                                onMouseLeave={() =>
                                                    setIsHoveredCopy(false)
                                                }
                                                style={{
                                                    color: isHoveredCopy
                                                        ? "#696969"
                                                        : "silver",
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ContentCopyOutlinedIcon />
                                            </IconButton>
                                            <Snackbar
                                                open={isSnackbarOpen}
                                                autoHideDuration={3000}
                                                onClose={handleSnackbarClose}
                                                message="Copied!"
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                sx={{
                                                    marginTop: "64px",
                                                    width: "100%",
                                                }}
                                            />
                                            <IconButton
                                                className="button"
                                                title="Share Content"
                                                onClick={openDialog}
                                                onMouseEnter={() =>
                                                    setIsHoveredShare(true)
                                                }
                                                onMouseLeave={() =>
                                                    setIsHoveredShare(false)
                                                }
                                                style={{
                                                    color: isHoveredShare
                                                        ? "#696969"
                                                        : "silver",
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ReplyIcon className="mirrored-icon" />
                                            </IconButton>
                                            <Dialog open={isOpen} onClose={closeDialog}>
                                                <div className="share-dialog">
                                                    <DialogTitle style={{ fontSize: "20px", color: "white", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#00490A", padding: "10px" }}>
                                                        Share this via
                                                        <CancelOutlinedIcon style={window.innerWidth <= 480 ? { position: 'absolute', top: '3px', right: '3px', cursor: 'pointer', fontSize: "15px" } : { position: 'absolute', top: '5px', right: '5px', cursor: 'pointer', fontSize: "20px" }} onClick={closeDialog} />
                                                    </DialogTitle>
                                                    <DialogContent style={{ fontSize: "17.5px", padding: "6px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                                            <IconButton onClick={handleLinkedInClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                                <LinkedinIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                            </IconButton>
                                                            <ShareTextOnTwitter text={twitterText} />
                                                            <IconButton onClick={handleFacebookClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                                <FacebookIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                            </IconButton>
                                                            <IconButton onClick={handleWhatsAppClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                                <WhatsappIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                            </IconButton>
                                                            <IconButton onClick={handleEmailClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                                <EmailIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                            </IconButton>
                                                            <Snackbar
                                                                open={snackbarOpenShare}
                                                                autoHideDuration={5000}
                                                                onClose={handleShareSnackbarClose}
                                                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                                                message={`Just paste the conversation on the ${redirectMessage} post and share. Redirecting in ${secondsLeft} seconds...`}
                                                            />
                                                        </div> */}
                                                        <ShareIcons
                                                            handleLinkedInClick={handleLinkedInClick}
                                                            handleFacebookClick={handleFacebookClick}
                                                            handleWhatsAppClick={handleWhatsAppClick}
                                                            handleEmailClick={handleEmailClick}
                                                            snackbarOpenShare={snackbarOpenShare}
                                                            handleShareSnackbarClose={handleShareSnackbarClose}
                                                            redirectMessage={redirectMessage}
                                                            secondsLeft={secondsLeft}
                                                            twitterText={twitterText}
                                                        />
                                                    </DialogContent>
                                                </div>
                                            </Dialog>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AudioMessage;
